import request from "@/api/service";

const api = {
   total: '/pms/makeNote/total',
   page: '/pms/makeNote/page',
   delete: '/pms/makeNote/delete',
   excel: '/pms/makeNote/makeNoteExcel',
}

export function getTotal(params) {
   return request({
      url: api.total,
      method: 'POST',
      data: params
   })
}

export function getPage(query, params) {
   return request({
      url: api.page + query,
      method: 'POST',
      data: params
   })
}

export function delDetail(query) {
   return request({
      url: api.delete + query,
      method: 'POST',
   })
}

export function getMakeNoteExcel(params) {
   return request({
      url: api.excel,
      method: 'GET',
      params,
      responseType: 'blob'
   })
}
