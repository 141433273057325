<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <el-row class="search-box">
      <el-row class="search-row">
        <el-row class="search-item">
          <date-packer size="medium" :datePacker="dateRange" @setDatePacker="getDatePacker" format="yyyy-MM-dd" align="left"></date-packer>
        </el-row>
      </el-row>
    </el-row>
    <div class="title"><h3>记一笔概况</h3></div>
    <el-row class="card-box" type="flex">
      <dl v-for="(item, index) in parseStatisticsTotal" :key="index">
        <dt><el-image :src="require(`@/assets/make-note-icon${index + 1}.png`)"></el-image></dt>
        <dd><p>{{ item.name }}</p><span>¥{{ item.total }}</span></dd>
      </dl>
    </el-row>
    <div class="title m-top-50">
      <h3>记一笔收支统计</h3>
    </div>
    <div class="chart-box">
      <el-button-group>
        <el-button @click="reType = 'item'" size="medium" :type="reType === 'item' ? 'primary' : '' ">按项目</el-button>
        <el-button @click="reType = 'pay'" size="medium" :type="reType === 'pay' ? 'primary' : ''">按支付方式</el-button>
      </el-button-group>
      <el-row class="chart-wrap" v-show="reType === 'item'">
        <el-col :span="12"><div class="chart"><div id="chart1"></div></div></el-col>
        <el-col :span="12"><div class="chart"><div id="chart2"></div></div></el-col>
      </el-row>
      <el-row class="chart-wrap" v-show="reType === 'pay'">
        <el-col :span="12"><div class="chart"><div id="chart3"></div></div></el-col>
        <el-col :span="12"><div class="chart"><div id="chart4"></div></div></el-col>
      </el-row>
    </div>
    <div class="title m-top-50">
      <h3>记一笔明细<span v-if="dateRange.length > 0">{{ dateRange[0] + '~' +dateRange[1] }}</span></h3>
    </div>
    <div class="chart-box">
      <el-row type="flex" justify="space-between">
        <div>
          <el-select size="medium" v-model="makeType" placeholder="请选择" @change="changeMakeType">
            <el-option v-for="(val, key) in { '': '全部类型', 'INCOME': '收入', 'EXPEND': '支出' }" :key="key"
                       :label="val" :value="key">
            </el-option>
          </el-select>
          <el-select @change="getDetailedList" size="medium" v-show="makeType === 'INCOME' || makeType === 'EXPEND'"
                     class="m-left-10" v-model="projectIds" placeholder="请选择" multiple>
            <el-option v-for="item in filterMakeNoteOpt" :key="item.id"
                       :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <el-button size="medium" @click="handleExcel" type="primary">报表导出</el-button>
      </el-row>
      <div class="table-box">
        <el-table class="m-top-15" :data="makeNoteData" style="width: 100%" border size="mini">
          <el-table-column prop="createTime" label="时间"></el-table-column>
          <el-table-column label="类型" width="90">
            <template slot-scope="scope">{{ scope.row.makeType | filterMakeType }}</template>
          </el-table-column>
          <el-table-column prop="projectName" label="项目" width="110"></el-table-column>
          <el-table-column prop="amount" label="金额" width="90"></el-table-column>
          <el-table-column prop="paymentMethod" label="收款方式" width="100">
            <template slot-scope="scope">
              {{ scope.row.paymentMethod | filterPayMethod(that) }}
            </template>
          </el-table-column>
          <el-table-column prop="roomIds" label="关联房间"></el-table-column>
          <el-table-column label="凭证" width="300">
            <template slot-scope="scope" v-if="scope.row.certificateUrls">
              <el-image v-for="(item, index) in scope.row.certificateUrls"
                        :src="item" :key="index" :preview-src-list="[ item ]"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
          <el-table-column prop="createOperator" label="操作人"></el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button @click="handleEdit(scope.row)" type="text">编辑</el-button>
              <el-button @click="handleDel(scope.row.id)" type="text">
                <el-link type="danger">删除</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="handleCurrPage"/>
        <span class="detail-total">共计0条记录，净收入：<i>¥{{ detailTotal.netIncome }}</i> | 总收入：<i>¥{{ detailTotal.totalIncome }}</i> | 总支出：<i>¥{{ detailTotal.totalExpend }}</i></span>
      </div>
    </div>
    <make-note ref="makeNote" v-if="visibleMakeNote" :visible="visibleMakeNote" @closeMakeNote="visibleMakeNote = false"
               :row="makeNoteRow" action="edit" @reload="getDetailedList"/>
  </div>
</template>
<script>
import { getTotal, getPage, delDetail,
  getMakeNoteExcel } from "@/api/pms/statistics/makeASummary";
import { mapState } from 'vuex'
import { exportFile } from "@/common/js/common";
import { getList } from "@/api/pms/accommodationSetting/makeANoteSetting";
import makeNote from '@/components/local/Header/MakeNote';
import getDict from "@/utils/dataDict";
import { dateFactory } from "@/common/js/common";
export default {
  name: 'roomBusinessSummary',
  data() {
    return {
      crumbs: new Map([
        ['PMS'], ['统计中心'], ['记一笔汇总']
      ]),
      that: this,
      dateRange: [],
      total: 0,
      page: 1,
      limit: 0,
      statisticsTotal: {
        totalIncome: 0,
        totalExpend: 0,
        netIncome: 0
      },
      reType: 'item',
      makeType: '',
      detailTotal: {
        totalIncome: 0,
        totalExpend: 0,
        netIncome: 0
      },
      makeNoteData: [],
      makeNoteOpt: [],
      projectIds: [],
      visibleMakeNote: false,
      makeNoteRow: {},
    }
  },
  components: {
    makeNote
  },
  computed: {
    ...mapState(['hotelInfo', 'dictData']),
    filterMakeNoteOpt() {
      return this.makeNoteOpt.filter(i => i.itemType === this.makeType)
    },
    parseStatisticsTotal() {
      const totalArr = [
        { name: '净收入(元)', code: 'netIncome', total: 0 },
        { name: '总收入(元)', code: 'totalIncome', total: 0 },
        { name: '总支出(元)', code: 'totalExpend', total: 0 },
      ]
      for (const k in this.statisticsTotal) {
        totalArr.find(i => i.code === k).total = this.statisticsTotal[k]
      }
      return totalArr
    }
  },
  mounted() {
    this.limit = sessionStorage.getItem('pageSize') * 1;
    this.dateRange = [dateFactory.getDistanceToday(-6,false),dateFactory.dateFormat(false)]
    getDict('pay-method')
    this.getGeneralStatistics()
    this.getDetailedList()
  },
  methods: {
    // 获取记一笔概况统计
    getGeneralStatistics() {
      const params = {
        hotelId: this.hotelInfo.id,
        companyId: this.hotelInfo.storeId,
        beginDateStr: this.dateRange[0],
        endDateStr: this.dateRange[1]
      }
      const option = {
        tooltip: { trigger: 'item' },
        legend: { top: '10%', left: '10%' },
        color: ['#0881fe', '#8ce98a', '#f97f84', '#ffbf7e'],
        series: [
          {
            name: '总收入', type: 'pie', radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              formatter: `总收入(元)\n\n￥${ this.statisticsTotal.totalIncome }`,
              show: true, position: 'center', fontSize: '14', fontWeight: 'bold'
            },
            labelLine: { show: false },
            data: []
          }
        ]
      }
      getTotal(params).then(({ success, records }) => {
        if (success) {
          this.statisticsTotal.netIncome = records.netIncome
          this.statisticsTotal.totalExpend = records.totalExpend
          this.statisticsTotal.totalIncome = records.totalIncome
          const { incomeItemList, expendItemList, incomeCashierList, expendCashierList } = records
          const totalArr = [ incomeItemList, expendItemList, incomeCashierList, expendCashierList ]
          for (const [idx, arr] of totalArr.entries()) {
            option.series[0].data.length = 0
            // 注意totalArr数组存放数据的顺序不要随意变动
            if (idx === 0 || idx === 2) {
              option.series[0].name = '总收入'
              option.series[0].label.formatter = `总收入(元)\n\n￥${ this.statisticsTotal.totalIncome }`
            }else {
              option.series[0].name = '总支出'
              option.series[0].label.formatter = `总支出(元)\n\n￥${ this.statisticsTotal.totalExpend }`
            }
            for (const val of arr) {
              option.series[0].data.push({ name: val.name, value: val.amount })
            }
            this.$echarts.init(document.getElementById(`chart${idx + 1}`)).setOption(option);
          }
        }
      })
    },
    // 获取记一笔明细
    getDetailedList() {
      const query = `?limit=${ this.limit }&page=${ this.page }`,
            params = {
        companyId: this.hotelInfo.storeId,
        hotelId: this.hotelInfo.id,
        makeType: this.makeType,
        beginDateStr: this.dateRange[0],
        endDateStr: this.dateRange[1]
      }
      this.makeType && (params.projectIds = this.projectIds)
      getPage(query, params).then(({ success, records }) => {
        if (success) {
          this.detailTotal.netIncome = records.netIncome
          this.detailTotal.totalExpend = records.totalExpend
          this.detailTotal.totalIncome = records.totalIncome
          this.total = records.total && records.makeNotePage.total
          const makeNoteData = records.makeNotePage.records
          if (makeNoteData && Array.isArray(makeNoteData)) {
            makeNoteData.forEach(i => i.certificateUrls = JSON.parse(i.certificateUrls || '[]'))
            this.makeNoteData = makeNoteData
          }
        }
      })
    },
    // 获取订单时间
    getDatePacker(val) {
      this.dateRange = val
      this.getGeneralStatistics()
      this.getDetailedList()
    },
    // 改变当前页
    changePageNum(num) {
      this.limit = num
      this.getDetailedList()
    },
    // 改变每页数
    handleCurrPage(num) {
      this.page = num
      this.getDetailedList()
    },
    // 删除明细
    handleDel(id) {
      this.$confirm('确定删除当前明细？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delDetail(`/${ id }`).then(({ success }) => {
          if (!success) return
          this.$message({ message: '删除成功！', type: 'error' })
          this.getDetailedList()
        })
      })
    },
    // 导出明细报表
    handleExcel() {
      if (this.makeNoteData.length === 0) {
        return this.$message({ message: '空表格不能导出' })
      }
      const params = {
        hotelId: this.hotelInfo.id,
        beginDateStr: this.dateRange[0],
        endDateStr: this.dateRange[1],
        page: this.page, limit: this.limit,
      }
      this.makeType && (params.projectIds = this.projectIds.toString())
      getMakeNoteExcel(params).then(res => {
        this.$message({ message: '报表导出成功！', type: 'success' })
        exportFile(res, '记一笔明细报表')
      })
    },
    // 改变明细类型
    changeMakeType() {
      if ((this.makeType === 'INCOME' || this.makeType === 'EXPEND') &&
          this.makeNoteOpt.length === 0) this.getIncomeAndExpend()
      if (this.makeType === '') this.getDetailedList()
    },
    // 查询收入支出项
    getIncomeAndExpend() {
      const params = {
        hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId
      };
      getList(params).then(({ success, records }) => {
        if (success) this.makeNoteOpt = records
      })
    },
    // 编辑收入支出项
    handleEdit(row) {
      this.visibleMakeNote = true
      this.makeNoteRow = row
    }
  },
  filters: {
    filterPayMethod(val, that) {
      if (val && that.dictData['pay-method']) return that.dictData['pay-method'][val] || val
    },
    filterMakeType(val) {
      const obj = { INCOME: '收入', EXPEND: '支出' }
      if (val) return obj[val] || val
    }
  }
}
</script>
<style scoped lang="scss">
.cont {
  .title {
    margin: 35px 0 10px 0;
    h3{
      font-size: 18px;
      span { color: #B9B9B9; font-weight: normal; font-size: 12px; margin-left: 6px }
    }
  }
  .card-box {
    background: #FFFFFF;
    dl {
      display: flex; align-items: center; padding: 30px 0 30px 30px;
      width: 33.33%; position: relative;
      dt{
        margin: 0 20px 0 0;
        .el-image { width: 48px; height: 48px; background-size: 100% 100% }
      }
      dd {
        p { font-size: 14px; color: #999999; margin-bottom: 10px }
        span{ font-size: 28px; color: #087FFD; }
      }
    }
    dl:before {
      position: absolute; content: ''; width: 1px;
      height: 60px; background-color: #CCCED9; right: 0; top: 30px;
    }
  }
  .chart-box {
    padding: 0 0 30px 0;
    .chart-wrap {
      margin: 20px 0 0 0;
      & > div:nth-child(1) { padding: 0 15px 0 0 }
      & > div:nth-child(2) { padding: 0 0 0 15px }
      .chart {
        background: #FFFFFF; width: 100%;
        & > div { width: 800px; height: 300px; }
      }
    }
    .table-box{
      background: #FFFFFF; padding: 0 0 10px 0;
    }
  }
  .detail-total{
    font-size: 14px; color: #666666;
    i { color: red; font-style: normal }
  }
  .el-table{
    .el-image{ width: 60px; height: 60px; margin: 0 3px; border: #CCCCCC solid 1px; }
  }
}
</style>
